@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0px;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
}

.contenedor {
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.formulario-bo {
  min-width: 600px;
  padding: 0px !important;
  height: 100%;
}
.formularioWC,
.formulario {
  min-width: 600px;
  border-radius: 5px;
  padding: 20px;
  height: 100%;
}

.formularioWC > div,
.formulario-bo > div,
.formulario > div {
  margin-bottom: 20px;
}

.formulario-bo label,
.formulario label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  color: #1f1f1f;
}

.formularioWC select,
.formulario select,
.formulario input,
.formulario textarea {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 5px;
  color: #1f1f1f;
}

.formulario-bo select,
.formulario-bo input,
.formulario-bo textarea {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  width: 100%;
  color: #4c5866;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #808fa1;
  padding: 10px;
  margin-bottom: 5px;
}

.formulario-bo input:focus .formulario input:focus {
  outline: none;
  border: 2px solid #0085ff;
}

.formularioWC .error,
.formulario-bo .error,
.formulario .error {
  color: #e92b2d;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  padding-left: 12px;
}

.merchant-primary:hover {
  text-decoration: none;
  background-color: rgb(21, 101, 192) !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
    rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px !important;
}

.formularioWC .exito .formulario-bo .exito .formulario .exito {
  text-align: center;
  font-size: 14px;
  padding-top: 20px;
  color: #02a802;
}

.formularioWC
  input[type="radio"]
  .formulario-bo
  input[type="radio"]
  .formulario
  input[type="radio"] {
  width: auto;
}

.btn-container {
  position: absolute !important;
  margin-top: 35px !important;
  text-align: center !important;
  width: 100% !important;
}

.grid-buttons {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 50% !important;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
/* Bolivia */
.merchant-secondary-bol {
  padding: 8px 22px !important;
  width: 155px !important;
  text-transform: none !important;
  background-color: #fff !important;
  border: 1px solid #ffc10e !important;
  border-radius: 4px;
  color: rgb(64, 74, 86) !important;
  font-size: 16px !important;
  float: right;
  right: 25px;
}
.merchant-primary-bol {
  padding: 8px 22px !important;
  width: 155px !important;
  text-transform: none !important;
  background-color: rgb(255, 193, 14) !important;
  border-radius: 4px;
  color: rgb(64, 74, 86) !important;
  font-size: 16px !important;
  float: left;
}
.merchant-disabel {
  background-color: rgba(207, 213, 220, 1) !important;
}

.backgroundModal .MuiBackdrop-root {
  background-color: #ffffff72;
  backdrop-filter: blur(4px);
}
.formulario-bo .lada {
  width: 85% !important;
}

.v1 #radioBtn input[type="radio"] {
  accent-color: black !important;
  position: absolute;
  left: 25;
  width: 20px;
  height: 20px;
}

.v1 #radioBtn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
}
.v1 #radioBtn label {
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}
.v1 .radioLabel,
.v1 .radioDescription {
  margin-left: 30px;
  width: 100%;
  text-align: left;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> V2 Style <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */

.v2 {
  min-width: 600px;
  padding: 0px !important;
  height: 60vh;
}
.v2-container {
  overflow-y: auto;
}

.v2 > div {
  margin-bottom: 20px;
}

.v2 label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  color: #1f1f1f;
}

.v2 select,
.v2 input,
.v2 textarea {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  width: 100%;
  color: #4c5866;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #808fa1;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 5px;
}

.v2 input:focus {
  outline: none;
  border: 2px solid #0085ff;
}

.v2 .error {
  color: #e92b2d;
  font-weight: 600;
  font-size: 12px;
}

.merchant-primary {
  padding: 8px 22px !important;
  text-transform: none !important;
  background-color: rgb(255, 193, 14) !important;
  font-size: 16px !important;
  width: 50% !important;
  float: right;
}
.merchant-primary-return {
  padding: 8px 22px !important;
  text-transform: none !important;
  background-color: #ffffff !important;
  font-size: 16px !important;
  width: 45% !important;
  float: right;
  color: #363853;
}
.merchant-primary-return:hover {
  text-decoration: none;
  background-color: #ffffff !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
    rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px !important;
}
@media (max-width: 600px) {
  .capital-validate {
    /* mobile */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #363853 !important;
    border-color: #363853 !important;
    font-size: 16px !important;
    width: 100% !important;
    float: right;
    border-radius: 16px !important;
  }
  .capital-validate:disabled {
    /* mobile */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #cfd0df !important;
    border-color: #cfd0df !important;
    font-size: 16px !important;
    width: 100% !important;
    float: right;
    border-radius: 16px !important;
  }
}
@media (max-width: 600px) {
  .capital-next {
    /* mobile */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #363853 !important;
    border-color: #363853 !important;
    font-size: 16px !important;
    width: 100% !important;
    float: right;
    border-radius: 16px !important;
  }
  .capital-resend-primary {
    /* mobile */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #363853 !important;
    border-color: #363853 !important;
    font-size: 16px !important;
    width: 100% !important;
    float: right;
    border-radius: 16px !important;
  }
  .capital-back {
    /* mobile */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #fff !important;
    color: #363853 !important;
    border-color: #363853 !important;
    font-size: 16px !important;
    width: 100% !important;
    float: right;
    border-radius: 16px !important;
  }
}
@media (min-width: 600px) {
  .capital-validate {
    /* web */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #363853 !important;
    border-color: #363853 !important;
    border-width: 3px !important;
    border-style: solid !important;
    font-size: 16px !important;
    width: 90% !important;
    float: right;
    border-radius: 16px !important;
  }
  .capital-validate:disabled {
    /* web */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #cfd0df !important;
    border-color: #cfd0df !important;
    border-width: 3px !important;
    border-style: solid !important;
    font-size: 16px !important;
    width: 90% !important;
    float: right;
    border-radius: 16px !important;
  }
}
@media (min-width: 600px) {
  .capital-next {
    /* web */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #363853 !important;
    border-color: #363853 !important;
    border-width: 3px !important;
    border-style: solid !important;
    font-size: 16px !important;
    width: 90% !important;
    float: right;
    border-radius: 16px !important;
  }
  .capital-resend-primary {
    /* web */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #363853 !important;
    border-color: #363853 !important;
    border-width: 3px !important;
    border-style: solid !important;
    font-size: 16px !important;
    width: 90% !important;
    float: right;
    border-radius: 16px !important;
  }
  .capital-back {
    /* web */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #fff !important;
    color: #363853 !important;
    border-color: #363853 !important;
    border-width: 3px !important;
    border-style: solid !important;
    font-size: 16px !important;
    width: 90% !important;
    float: right;
    border-radius: 16px !important;
  }
}
@media (min-width: 600px) {
  .capital-back-small {
    /* web */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #fff !important;
    color: #363853 !important;
    border-color: #363853 !important;
    border-width: 3px !important;
    border-style: solid !important;
    font-size: 16px !important;
    width: 45% !important;
    float: right;
    border-radius: 16px !important;
  }
}
@media (max-width: 600px) {
  .capital-back-small {
    /* web */
    padding: 8px 22px !important;
    text-transform: none !important;
    background-color: #fff !important;
    color: #363853 !important;
    border-color: #363853 !important;
    border-width: 3px !important;
    border-style: solid !important;
    font-size: 16px !important;
    width: 45% !important;
    float: right;
    border-radius: 16px !important;
  }
}
.v2 .exito {
  text-align: center;
  font-size: 14px;
  padding-top: 20px;
  color: #02a802;
}

.v2 .merchant-secondary {
  padding: 8px 22px !important;
  width: 155px !important;
  text-transform: none !important;
  background-color: #fff !important;
  border: 1px solid #ffc10e !important;
  border-radius: 4px;
  color: rgb(64, 74, 86) !important;
  font-size: 16px !important;
  float: right;
  right: 25px;
}
.v2 .merchant-primary {
  padding: 8px 22px !important;
  width: 155px !important;
  text-transform: none !important;
  background-color: rgb(255, 193, 14) !important;
  border-radius: 4px;
  color: rgb(64, 74, 86) !important;
  font-size: 16px !important;
  float: left;
}

.v2 .lada {
  width: 85% !important;
}

#id_formContainer {
  flex: 1;
  border-radius: 20px 20px 0 0;
  padding: 50px 45px;
}

#id_steper {
  margin-top: 85px;
}

.no-information-container {
  height: 400px;
  width: 100%;
}
.no-information-message {
  height: 400px;
  min-width: 100%;
  text-align: center;
  position: absolute;
}

.modal-box .icon-close-modal {
  position: absolute;
  top: 13.75px;
  right: 22px;
  font-size: 20px;
  cursor: pointer;
}

.modal-box select,
.modal-box textarea,
.modal-box input {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 5px;
  color: #1f1f1f;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 16px 24px rgba(52, 60, 70, 0.14),
    0px 6px 30px rgba(52, 60, 70, 0.12), 0px 8px 10px rgba(52, 60, 70, 0.2);
  border-radius: 8px;
}

.modal-box textarea,
.modal-box input {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 5px;
  color: #1f1f1f;
}

.modal-box .buttonsInputFields {
  width: 100%;
  margin-top: 50px;
  display: flex;
}

.buttonsStepsDiv,
#id_formContainer {
  background-color: #f8f9fa;
  min-height: 100%;
}

.buttonsStepsContainer {
  position: absolute;
  top: 60%;
  padding: 0 !important;
  left: -3px;
}
.buttonsStepsDiv {
  justify-items: end;
  padding: 15px 65px;
  border-radius: 0 0 20px 20px;
  display: flex;
}

/* radio v2 */
.v2 #radioBtn label {
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}
.v2 #radioBtn input[type="radio"] {
  accent-color: #3aa741 !important;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0px;
  top: calc(50% - 20px);
}
.v2 #radioBtn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
}
.v2 .radioLabel,
.v2 .radioDescription {
  margin-left: 15px;
  width: 100%;
  text-align: left;
}
.v2 .radioDescription {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 30px;
  color: #708195;
  margin-top: 0px;
  font-size: 12px;
}
.v2 .rCard {
  width: 100%;
  background: #ffffff;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(194, 209, 217, 0.46);
  padding: 16px;
  height: 90px;
}
.v2 .rCard label {
  padding: 16px !important;
  font-size: 16px;
  color: #4c5866;
}
